<template>
    <div id="home-scroll">
        <section ref="el" class="map-cover"
        :style="[{cursor: isDragging ? 'grabbing' : 'grab',
        scrollSnapType: isDragging ? '' : '',}]"
        @mousedown="onMouseDown"
        @mouseup="onMouseUp"
        >
            <div class="map">
                <div class="radar">
                    <div class="planet" :style="plants[0].style">
                        <PlanetInfo v-if="isPlanetInfoView" v-bind:planet="plants[0]" @close-modal="isPlanetInfoView=false"/>
                        <img class="planet" :src="plants[0].imgUrl" @click="isPlanetInfoView=true"/>
                    </div>
                </div>
            </div>
            
        </section>

    </div>

</template>

<script>
import PlanetInfo from "../modal/PlanetInfo.vue";
export default {
    name: "HomeScroll",
    data() {
        return {
            isPlanetInfoView:false,
            isDragging: false,
            cursorPos: [0, 0],
            el: null,
            plants: [
                { 
                    id: "0", 
                    title: "yelime", 
                    imgUrl: require("../assets/earth.png"), 
                    link: "/",
                    style: {
                        "top": "47.5%",
                        "left": "47.5%",
                    } 
                },
            ]
        };
    },
    mounted() {
        window.addEventListener("mouseup", this.onMouseUp);
        console.log(this.$refs.el.scrollWidth);
        this.$refs.el.scrollBy({
            left: (this.$refs.el.scrollWidth - this.$refs.el.clientWidth) / 2,
            top: (this.$refs.el.scrollHeight - this.$refs.el.clientHeight) / 2,
        });
    },
    destroyed() {
        window.removeEventListener("mouseup", this.onMouseUp);
    },
    methods: {
        /** @param {MouseEvent} ev */
        onMouseDown(ev) {
            this.cursorPos = [ev.pageX, ev.pageY];
            this.isDragging = true;
            window.addEventListener("mousemove", this.onMouseHold);
        },
        /** @param {MouseEvent} ev */
        onMouseUp(ev) {
            console.log({ x: ev.clientX, y: ev.clientY });
            console.log(this.$refs.el.scrollLeft);
            console.log(this.$refs.el.scrollTop);
            console.log(this.$refs.el.scrollWidth);
            console.log(this.$refs.el.scrollWidth - this.$refs.el.clientWidth);
            window.removeEventListener("mousemove", this.onMouseHold);
            this.isDragging = false;
        },
        /** @param {MouseEvent} ev */
        onMouseHold(ev) {
            ev.preventDefault();
            requestAnimationFrame(() => {
                const delta = [
                    ev.pageX - this.cursorPos[0],
                    ev.pageY - this.cursorPos[1],
                ];
                this.cursorPos = [ev.pageX, ev.pageY];
                if (!this.$refs.el)
                    return;
                this.$refs.el.scrollBy({
                    left: -delta[0],
                    top: -delta[1],
                });
            });
        },
    },
    components: { PlanetInfo }
}
</script>

<style>
.planet{
    position:relative;
    background-size:cover;
    background-position: center;
    width: 10vw; 
    height: 10vh;
    min-width: 10vh;
    min-height: 10vw;
}
.touchtest{
    width: 100vw;
    height: 100vh;
}
#home-scroll>section::-webkit-scrollbar{
    display: none; 
}
#home-scroll{
    position:fixed;
    top:0;
    left:0;
    width: 100vw;
    height: 100vh;
}

.radar{
    border:1px dotted green;
    width: 200vw; 
    height: 200vh;
    min-width: 200vh;
    min-height: 200vw;
    background-size:cover;
    background-position: center;
    background-image: url("../assets/radar.png"); 
}
.radar::before{
    content:"";
    position:absolute;
    width: calc(80vw - 20px); 
    height: calc(80vh - 20px);
    max-width: calc(80vh - 20px);
    max-height: calc(80vw - 20px);
    opacity: 0.2;
    background-color: aqua;
}
.map{
    display: flex; 
    flex-wrap: wrap;
    width: 200vw; 
    height: 200vh;
    min-width: 200vh;
    min-height: 200vw;
}

.map-cover{
    margin: 15vh auto 0;
    overflow:scroll;
    border:10px solid yellow; 
    width: 80vw; 
    height: 80vh;
    max-width: 80vh;
    max-height: 80vw;
}
</style>