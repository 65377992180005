<template>
    <div class="modal">
        <div class="overlay" @click="$emit('close-modal')"></div>
        <div class="modal-card">
            <img class="planet-img" :src="planet.imgUrl"/>
            행성이름 : {{planet.title}}
            <div>
                <v-btn @click="golink(planet.link)">출발</v-btn>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name : 'PlanetInfo',
    props:{
        planet : Object
    },
    methods:{
        golink(link){
            console.log(link);
            this.$router.push(link);
        }
    }

}
</script>

<style scoped>
.modal,
.overlay{
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top:0;
    z-index:10;
    text-align: left;
}
.overlay{
    opacity: 0.5;
    background-color:black;
}
.modal-card{
    position:relative;
    max-width: 40%;
    margin:auto;
    margin-top: 100px;
    padding:20px;
    background-color: white;
    min-height: 500px;
    z-index:10;
    opacity:1;
}

.planet-img{
    position:relative;
    background-size:cover;
    background-position: center;
    
    width: 100%; 
    height: 100%;
}
</style>