<template>
    <div class="home-main">
        <!-- <div class="home-ment">
            <div class="kor-text">
                새로운 것을 배우고
            </div>
            <div class="eng-text">
                Let's Learn New Thing
            </div>
        </div>
        <div class="home-ment">
            <div class="kor-text">
                내 인생을 발전시키자!
            </div>
            <div class="eng-text">
                And Develop My Life
            </div>
        </div> -->
        <div class="home-content">
            <!-- <hooper :vertical="true" :centerMode="true" :infiniteScroll="true" :itemsToShow="1.5" :itemsToSlide="1" style="height:60vh;">
                <slide style="background-color:gray;height:40vh;">
                    e
                </slide>
                <slide style="background-color:red;height:40vh;">
                    a
                </slide>
                <slide style="height:40vh;">
                    <div style="height:100%; overflow: hidden;">
                        <img style="width: 100%; height:100%;object-fit: fill;" :src="require(`@/assets/route.png`)"/>

                    </div>
                </slide>
                <slide style="height:40vh;" >
                    <div style="height:100%; overflow: hidden;">
                        <img style="width: 100%; height:100%;object-fit: fill;" :src="require(`@/assets/route.png`)"/>

                    </div>
                </slide>
                <slide style="height:40vh;">
                    <div style="height:100%; overflow: hidden;">
                        <img style="width: 100%; height:100%;object-fit: fill;" :src="require(`@/assets/route.png`)"/>

                    </div>
                </slide>
            </hooper> -->
            <HomeScroll/>
        </div>
    </div>
</template>
<script>
// import {Hooper, Slide} from 'hooper';
// import 'hooper/dist/hooper.css';
import HomeScroll from './HomeScroll.vue';

export default {
    data(){
        return{
            test:`<div>tests<br>
            asd</div>`,
        }
    },
    methods:{
        add(){
            this.buttons='testmy'
        }
    },
    components: {
        // Hooper,
        // Slide,
        HomeScroll
    },
}
</script>

<style scoped>
.home-main::-webkit-scrollbar{
    /* overflow: hidden !important; */
    display: none;
}
.home-main{
    margin: 0;
    /* max-height: 100px; */
    height: calc(100vh);
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* overflow: hidden !important; */
	overflow-y:scroll;
    background-image: url("../assets/universe.png");
    background-size:cover;
    background-position: center;
}
.home-main>div{
    margin: 0 auto;
}
.kor-text{
    font-size: 6vmin;
    text-align: center;
    background-color: rgba(234, 237, 46, 0.813);
    padding: 1vh 0 0;
    margin: 1vh 1vw 0;
    width: 70vmin;
    border-radius: 65px 0 0 0;
}

.eng-text{
    background-color: rgba(242, 163, 37, 0.929);
    padding: 1vh 0 0;
    margin: 0 1vw 0;
    text-align: center;
    width: 70vmin;
    border-radius: 0 0 65px 0;

}
.home-ment{
    justify-content: center;
}
.home-content{
    /* height: calc(100vh - 10px); */
    height: 90vh;
    width: 90vw;
}
.testimg{
    background-image: url("../assets/ready.png");
    background-size: 200px;
}

.hooper-slide.is-current {
  transform: scale(1);
}

.touchtest{
    width: 200vw;
    height: 200vh;
    background-color: aqua;
    
}
</style>

